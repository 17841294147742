/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import axios from "axios";
import Swal from "sweetalert2";
import {authHeader, getAccountPlatformObj, getLoggedInUser} from './authservice/auth-header';
// import {customEncode} from "@/helpers/util"
import { javaAPIInstance } from './java-axio-instance';
import { getPlatformRedirectUrl } from './micro-service';

const instance = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}rest/`
});

export const axiosInstance = axios;

// request interceptor
instance.interceptors.request.use(
    async config => {
        let authData = await authHeader();

        // Do something before request is sent
        if (authData['Authorization']) {
            config.headers["x-api-token"] = authData['Authorization'];
            config.headers["x-app-id"] = 'octilliontv';
        }

        if (authData['token']) {
            config.headers["Authorization"] = `Bearer ${authData['token']}`;
            config.headers["x-app-id"] = 'octilliontv';
        }

        if (config.method == 'put' || config.method == 'post') {
            let payloadData = config.data;
            if (payloadData != null && payloadData != undefined && (payloadData.deleted_at == '' || payloadData.deleted_at != '')) {
                delete payloadData.deleted_at;
            }
            if (payloadData.advertiser_id) {
                payloadData.advertiser_id = parseInt(payloadData.advertiser_id);
            }
            config.data = payloadData;
            console.log(config.data);
        }
        // let refineParam = {};
        // if(config.params) {
        //     Object.keys(config.params).forEach(ele => {
        //         refineParam[ele] = customEncode(config.params[ele]);
        //     });
        // }
        // config.params = refineParam;
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

// Add a response interceptor
instance.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    console.log(error.response);
    if (error.response && 401 === error.response.status && !error.response.config.url.includes('/authenticate')) {
        let second = 5;
        Swal.fire({
            title: "Session Expired",
            text: `Your session has expired. You will be redirected to login page automatically in ${second}sec.`,
            type: "warning",
            showCancelButton: false,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Login",
            closeOnConfirm: false
        }).then(result => {
            if (result.value) {
                window.localStorage.setItem('redirectFrom', window.location.pathname + window.location.search);
                window.location = '/logout';
            }
        });
        setTimeout(() => {
            window.localStorage.setItem('redirectFrom', window.location.pathname + window.location.search);
            window.location = '/logout';
        }, 5000);
    } else if (error.response && 403 === error.response.status) {
        console.log("error.response", error.response);
        let request = error.response.config.url.split('/');
        if (/^-?\d+(\.\d+)?$/.test(request[request.length - 1])) {
           request=request[request.length - 2]
        } else {
            request=request[request.length - 1]
        }
        console.log("request", request);
        Swal.fire({
            title: "Access Denied",
            text: `You don't have permission to access "${request}".`,
            type: "warning",
            showCancelButton: false,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Ok",
            closeOnConfirm: false
        }).then(result => {
            if (result.value) {
                window.location = getPlatformRedirectUrl();
            }
        });
    }
    //  else if (error.response && 500 === error.response.status) {
    //     console.log(error);
    //     let request = error.response.config.url.split('/');
    //     Swal.fire({
    //         title: error ? error.message : (error.data && error.data.message ? error.data.message : "Server Internal Error"),
    //         text: `Something went wrong, please try again after sometime.`,
    //         type: "warning",
    //         showCancelButton: false,
    //         confirmButtonColor: "#DD6B55",
    //         confirmButtonText: "Ok",
    //         closeOnConfirm: false
    //     }).then(result => {
    //         if (result.value) {
    //             // window.location = getPlatformRedirectUrl();
    //         }
    //     });
    // } 
    else if (error.response && 412 === error.response.status) {

        Swal.fire({
            title: "Invalid Precondition Failed",
            text: `${error.response.data.message}`,
            type: "error",
            showCancelButton: false,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Dashboard",
            closeOnConfirm: false
        }).then(result => {
            if (result.value) {
                window.location = getPlatformRedirectUrl();
            }
        });
    } else {
        if (axios.isCancel(error)) {
            console.log('Request canceled:', error.message);
            return Promise.reject({message: error.message, status: 'cancelled'});
        } else {
            // Ensure error.response and error.response.data are defined
            if (typeof error.response.data === 'object') {
                error.response.data.status = error.response.status;
            }
            // Return the error response
            return Promise.reject(error.response);
        }
    }
});

export const userAuth = (payload) => {
    return instance.post(`users/authenticate`, payload);
}

export const switchUserAccount = (payload) => {
    return instance.post(`users/switch_account`, payload);
}
export const requestResetPasswordApi = (payload) => {
    return instance.post(`/forgot-password/send`, payload);
}
export const updatePasswordApi = (payload) => {
    return instance.put(`/forgot-password/update`, payload);
}
export const checkEmailExistForgetPassApi = (payload) => {
    return instance.get(`/forgot-password/check`, {params: payload});
}

//activity api list
export const getActivityLogApi = (payload) => {
    return instance.get(`activity_logs`, {params: {...getAccountPlatformObj(), ...payload}});
}

// buyingGroup api list
export const getBuyingGroups = (payload) => {
    return instance.get(`buying_groups`, {params: {...getAccountPlatformObj(), ...payload}});
}

export const buyingGroupExists = (payload) => {
    return instance.get(`buying_groups/findByName`, {params: {...payload, ...getAccountPlatformObj(false, false, false, false, true)}});
}
export const buyingGroupDomainExists = (payload) => {
    return instance.get(`buying_groups/findByDomain`, {params: {...payload}});
}

export const getSingleBuyingGroup = (id) => {
    return instance.get(`buying_groups/${id}`, {params: {...getAccountPlatformObj()}});
}
export const postBuyingGroupApi = (payload) => {
    return instance.post(`buying_groups`, {...payload, ...getAccountPlatformObj(true)});
}

export const putBuyingGroupApi = (payload) => {
    return instance.put(`buying_groups/${payload.id}`, {...payload, ...getAccountPlatformObj(false, true)});
}

export const deleteBuyingGroupApi = (payload) => {
    return instance.delete(`buying_groups/${payload.id}`);
}


export const getParentCompanies = (payload) => {
    return instance.get(`customers`, {params: {...getAccountPlatformObj(), ...payload}});
}
export const parentCompanyIsExists = (payload) => {
    return instance.get(`customers/findByName`, {params: {...payload, ...getAccountPlatformObj(false, false, false, false, true)}});
}

export const postParentCompanyApi = (payload) => {
    return instance.post(`customers`, {...payload, ...getAccountPlatformObj(true)});
}

export const getSingleParentCompany = (id) => {
    return instance.get(`customers/${id}`, {params: {...getAccountPlatformObj()}});
}

export const putParentCompanyApi = (payload) => {
    return instance.put(`customers/${payload.id}`, {...payload, ...getAccountPlatformObj(false, true)});
}

export const deleteParentCompanyApi = (payload) => {
    return instance.delete(`customers/${payload.id}`);
}

export const getParentCompaniesRecord = (payload) => {
    return instance.get(`customers/download`, {
        params: {...getAccountPlatformObj(), ...payload},
        responseType: 'blob',
    });
}


export const getBuyingGroupRecord = (payload) => {
    return instance.get(`buying_groups/download`, {
        params: {...getAccountPlatformObj(), ...payload},
        responseType: 'blob',
    });
}

// manufacturer api

export const getManufacturers = (payload) => {
    return instance.get(`manufacturers`, {params: {...getAccountPlatformObj(), ...payload}});
}
export const getManufacturersRecord = (payload) => {
    return instance.get(`manufacturers/download`, {
        params: {...getAccountPlatformObj(), ...payload},
        responseType: 'blob',
    });
}
export const getSingleManufacturer = (id) => {
    return instance.get(`manufacturers/${id}`, {params: {...getAccountPlatformObj()}});
}

export const manufacturersExists = (payload) => {
    return instance.get(`manufacturers/findByName`, {params: {...payload, ...getAccountPlatformObj(false, false, false, false, true)}});
}

export const manufacturersDomainExists = (payload) => {
    return instance.get(`manufacturers/findByDomain`, {params: {...payload}});
}

export const postManufacturerApi = (payload) => {
    return instance.post(`manufacturers`, {...payload, ...getAccountPlatformObj(true)});
}


export const putManufacturerApi = (payload) => {
    return instance.put(`manufacturers/${payload.id}`, {...payload, ...getAccountPlatformObj(false, true)});
}

export const deleteManufacturerApi = (payload) => {
    return instance.delete(`manufacturers/${payload.id}`);
}

//accounts api list
export const getAccountsApi = (payload) => {
    return instance.get(`accounts`, {params: {...getAccountPlatformObj(), ...payload}});
}

export const postAccountApi = (payload) => {
    return instance.post(`/accounts`, {...payload, ...getAccountPlatformObj(true)});
}
export const postFullAccountApi = (payload) => {
    return instance.post(`/accounts?full=1`, {...payload, ...getAccountPlatformObj(true)});
}
export const putAccountApi = (payload) => {
    const obj = getAccountPlatformObj(false, true)
    delete obj.account_id;
    delete obj.platform_id;
    delete obj.created_by;
    return instance.put(`accounts/${payload.id}`, {...payload, ...obj});
}
export const putFullAccountApi = (payload) => {
    const obj = getAccountPlatformObj(false, true)
    delete obj.account_id;
    delete obj.platform_id;
    delete obj.created_by;
    return instance.put(`accounts/${payload.id}?full=1`, {...payload, ...obj});
}
export const deleteAccountApi = (payload) => {
    return instance.delete(`accounts/${payload.id}`);
}
export const getSingleAccountApi = (id) => {
    return instance.get(`accounts/${id}`, {params: {...getAccountPlatformObj()}});
}
export const getFullSingleAccountApi = (id) => {
    return instance.get(`accounts/${id}?full=1`, {params: {...getAccountPlatformObj()}});
}

//advertisers api list
export const getAdvertisers = (payload) => {
    let body = {...getAccountPlatformObj(), ...payload};
    if(body.in_account_id) {
        delete body.account_id;
    }
    return instance.get(`advertisers`, {params: body});
}


export const getAdvertisersRecord = (payload) => {
    return instance.get(`advertisers/download`, {
        params: {...getAccountPlatformObj(), ...payload},
        responseType: 'blob',
    });
}

export const getSingleAdvertiser = (id) => {
    return instance.get(`advertisers/${id}`, {params: {...getAccountPlatformObj()}});
}

export const getSingleAdvertiserByAdvertiserId = (payload) => {
    return instance.get(`advertisers`, {params: {...payload, ...getAccountPlatformObj()}});
}

export const advertisersExists = (payload) => {
    return instance.get(`advertisers/findByName`, {params: {...payload, ...getAccountPlatformObj(false, false, false, false, true)}});
}
export const advertisersDomainExists = (payload) => {
    return instance.get(`advertisers/findByDomain`, {params: {...payload}});
}


export const getAdvertiserCategory = (payload) => {
    return instance.get(`advertiser_categories`, {params: {...payload}});
}
export const getGenreList = (payload) => {
    return instance.get(`/ref/genres`, {params: {...payload}});
}

export const postAdvertiserApi = (payload) => {
    return instance.post(`advertisers`, {...payload, ...getAccountPlatformObj(true)});
}
export const postFavoriteAdvertiserApi = (payload) => {
    return instance.post(`favorites`, {...payload, ...getAccountPlatformObj(true)});
}

export const deleteAdvertiserApi = (payload) => {
    return instance.delete(`advertisers/${payload.id}`);
}
export const deleteFavoriteAdvertiserApi = (payload) => {
    return instance.delete(`favorites/${payload.id}`);
}

export const putAdvertiserApi = (payload) => {
    return instance.put(`advertisers/${payload.id}`, {...payload, ...getAccountPlatformObj(false, true)});
}


//Roles api list
export const getRoles = (payload) => {
    return instance.get(`roles`, {params: {...getAccountPlatformObj(), ...payload}});
}
export const postRolesApi = (payload) => {
    return instance.post(`roles`, {...payload, ...getAccountPlatformObj(true)});
}
export const putRolesApi = (payload) => {
    return instance.put(`roles/${payload.id}`, {...payload, ...getAccountPlatformObj(false, true)});
}
export const getRoleApi = (id) => {
    return instance.get(`roles/${id}`, {params: {...getAccountPlatformObj()}});
}
export const deleteRolesApi = (payload) => {
    return instance.delete(`roles/${payload.id}`);
}


//creatives Add Ons Api
export const getAddOnApi = (payload = {}) => {
    return instance.get(`creative_addons`, {params: {...payload, ...getAccountPlatformObj()}});
}
export const deleteAddOnApi = (payload) => {
    return instance.delete(`creative_addons/${payload.id}`);
}
export const postAddOnApi = (payload) => {
    return instance.post(`creative_addons`, {...payload, ...getAccountPlatformObj(true)});
}
export const getSingleAddOn = (id) => {
    return instance.get(`creative_addons/${id}`, {params: {...getAccountPlatformObj()}});
}
export const putAddOnApi = (payload) => {
    return instance.put(`creative_addons/${payload.id}`, {...payload, ...getAccountPlatformObj(false, true)});
}

// Deals Api
export const getDealApi = (payload = {}) => {
    return instance.get(`deals`, {params: {...payload}});
}
export const deleteDealApi = (payload) => {
    return instance.delete(`deals/${payload.id}`);
}
export const postDealApi = (payload) => {
    return instance.post(`deals`, {...payload, ...getAccountPlatformObj(true)});
}
export const getSingleDeal = (payload) => {
    return instance.get(`deals/${payload.id}`, {params: {...payload, ...getAccountPlatformObj()}});
}
export const putDealApi = (payload) => {
    return instance.put(`deals/${payload.id}`, {...payload, ...getAccountPlatformObj(false, true)});
}
export const dealExistAPi = (payload) => {
    return instance.get(`deals/findByDealID`, {params: {...payload}});
}
export const getDealFilterApi = (payload = {}) => {
    return instance.post(`deals/filter`, {...payload, ...getAccountPlatformObj(false, false, false, false, true)});
}
export const getDealsRecordApi = (payload) => {
    return instance.get(`deals/download`, {
        params: {...getAccountPlatformObj(), ...payload},
        responseType: 'blob',
    });
}

//Lists Api
export const getListApi = (payload = {}) => {
    let paramPayload = {...payload, ...getAccountPlatformObj()}
    if (payload.isGlobal) {
        delete paramPayload.isGlobal
    }
    console.log('payload', payload)

    return instance.get(`custom_lists`, {params: paramPayload});
}
export const deleteListApi = (payload) => {
    return instance.delete(`custom_lists/${payload.id}`);
}
export const postListApi = (payload) => {
    return instance.post(`custom_lists`, {...payload, ...getAccountPlatformObj(true)});
}
export const getSingleList = (id) => {
    return instance.get(`custom_lists/${id}`, {params: {...getAccountPlatformObj()}});
}
export const putListApi = (payload) => {
    return instance.put(`custom_lists/${payload.id}`, {...payload, ...getAccountPlatformObj(false, true)});
}

//Lists Items Api
export const getListItemsApi = (payload = {}) => {
    return instance.get(`/list_items`, {params: {...payload}});
}
export const deleteListItemApi = (payload) => {
    return instance.delete(`/list_items/${payload.id}`);
}
export const deleteAllListItemApi = (payload) => {
    return instance.delete(`/list_items/all`, {params: {...payload, ...getAccountPlatformObj()}});

}
export const postListItemApi = (payload) => {
    return instance.post(`/list_items`, {...payload, ...getAccountPlatformObj(true)});
}
export const getCustomListItemRecord = (payload) => {
    return instance.get(`list_items/download`, {
        params: {...getAccountPlatformObj(), ...payload},
        responseType: 'blob',
    });
}
export const duplicateLineitemApi = (payload) => {
    return instance.post(`/line_items/duplicate`, {...payload, ...getAccountPlatformObj(true)});
}

export const getLineItemsRecord = (payload) => {
    return instance.get(`line_items/download`, {
        params: {...getAccountPlatformObj(), ...payload},
        responseType: 'blob',
    });
}

export const fetchBiddingStatFlagsApi = (payload = {}) => {
    return instance.get(`/reports/line_item_bid_status`, {params: {...payload, ...getAccountPlatformObj()}});
}

export const getSingleListItem = (id) => {
    return instance.get(`/list_items/${id}`, {params: {...getAccountPlatformObj()}});
}
export const putListItemApi = (payload) => {
    return instance.put(`/list_items/${payload.id}`, {...payload, ...getAccountPlatformObj(false, true)});
}
//Users Api
export const getUserApi = (payload = {}) => {
    let platformObject=getAccountPlatformObj();
    if(payload.account_ids){
        delete platformObject.account_id
        delete platformObject.platform_id
    }
    return instance.get(`/users`, {params: {...payload, ...platformObject}});
}
export const getUserWithPaginationAapi = (payload = {}) => {
    return instance.get(`/users/paginate`, {params: {...payload, ...getAccountPlatformObj()}});
}
export const deleteUserApi = (payload) => {
    return instance.delete(`/users/${payload.id}`);
}
export const postUserApi = (payload) => {
    return instance.post(`/users`, {...payload, ...getAccountPlatformObj(true)});
}
export const getSingleUser = (id) => {
    return instance.get(`/users/${id}`, {params: {...getAccountPlatformObj()}});
}
export const putUserApi = (payload) => {
    let payloadBody = {...payload, ...getAccountPlatformObj(false, true)};
    if (payload.account_id) {
        payloadBody.account_id = payload.account_id;
    }
    return instance.put(`/users/${payload.id}`, payloadBody);
}

export const getUsersRecord = (payload) => {
    return instance.get(`users/download`, {
        params: {...getAccountPlatformObj(), ...payload},
        responseType: 'blob',
    });
}

export const putUserProfileApi = (payload) => {
    let payloadBody = {...payload, ...getAccountPlatformObj(false, true)};
    if (payload.account_id) {
        payloadBody.account_id = payload.account_id;
    }
    return instance.put(`/users/profile/${payload.id}`, payloadBody);
}
export const emailExistsApi = (payload) => {
    return instance.get(`/users/findByEmail`, {params: {...payload}});
}

// Campaigns
export const getCampaigns = (payload = {}) => {
    let refinedPayload = getRefineObj(payload);
    let configObject = {
        params: {
            ...refinedPayload.payload,
            ...getAccountPlatformObj()
        },
        ...refinedPayload.cancelToken
    };
    let url = 'campaigns';
    if (window.location.href.includes("/analytics/attribution/web")||window.location.href.includes("/analytics/attribution/sales")||window.location.href.includes("/analytics/attribution/location")) {
        url = 'campaigns/attribution';
    }
    return instance.get(url, configObject);
}
export const getCampaignsSummary = (payload = {}) => {
    return instance.get(`reports/summary`, {params: {...payload, ...getAccountPlatformObj()}});
}


export const deleteManufactureBuyingGroup = (type, id) => {
    if (type == 'buying_group') {
        return instance.delete(`${type}_campaigns/${id}`);
    }
    return instance.delete(`${type}_campaign/${id}`);
}


export const getCampaignsRecord = (payload) => {
    return instance.get(`campaigns/download`, {
        params: {...getAccountPlatformObj(), ...payload},
        responseType: 'blob',
    });
}

export const getBudgetFlightsRecord = (payload) => {
    return instance.get(`budget_flights/download`, {
        params: {...getAccountPlatformObj(), ...payload},
        responseType: 'blob',
    });
}

export const getVendorFeesApi = (payload = {}) => {
    return instance.get(`vendors`, {params: {...payload, ...getAccountPlatformObj()}});
}

export const campaignExistApi = (payload) => {
    return instance.get(`campaigns/findByName`, {params: {...payload}});
}

export const getCampaignVendorFeesApi = (payload) => {
    return instance.get(`vendor_fees`, {params: {...payload, ...getAccountPlatformObj(true)}});
}

export const postCampaignApi = (payload) => {
    return instance.post(`campaigns`, {...payload, ...getAccountPlatformObj(true)});
}

export const duplicateCampaignApi = (payload) => {
    return instance.post(`campaigns/duplicate`, {...payload, ...getAccountPlatformObj(true)});
}

export const postCampaignFindAndReplaceApi = (payload) => {
    return instance.post(`campaigns/findAndReplace`, {...payload, ...getAccountPlatformObj(true)});
}
export const postImportCampaignLineItemApi = (payload) => {
    return instance.post(`line_items/import`, {...payload, ...getAccountPlatformObj(true)});
}

export const deleteCampaignApi = (payload) => {
    return instance.delete(`campaigns/${payload.id}`);
}
export const deleteBulkCampaignApi = (payload) => {
    return instance.delete(`campaigns/bulkDelete`, {data: {...payload, ...getAccountPlatformObj(true)}});
}

export const isCampaignNameExist = (payload) => {
    return instance.post(`campaigns/findByName`, {...payload});
}

export const postCampaignMultipleVendorFeeApi = (payload) => {
    console.log('vendor payload', payload)
    return instance.post(`vendor_fees/multiple`, {...payload, ...getAccountPlatformObj(true)});
}

export const postCampaignVendorFreeApi = (payload) => {
    return instance.post(`vendor_fees`, {...payload, ...getAccountPlatformObj(true)});
}

export const putCampaignVendorFeesApi = (payload) => {
    return instance.put(`vendor_fees/${payload.id}`, {...payload, ...getAccountPlatformObj(false, true)});
}

export const deleteCampaignVendorFeesApi = (payload) => {
    return instance.delete(`vendor_fees/${payload.id}`);
}

export const getCampaignBudgetFlightApi = (payload) => {
    return instance.get(`budget_flights`, {params: {...payload, ...getAccountPlatformObj(true)}});
}

export const postCampaignBudgetFlightApi = (payload) => {
    return instance.post(`budget_flights?multiple=true`, {...payload, ...getAccountPlatformObj(true)});
}

export const putCampaignBudgetFlightApi = (payload) => {
    return instance.put(`budget_flights/${payload.id}`, {...payload, ...getAccountPlatformObj(false, true)});
}

export const deleteCampaignBudgetFlightApi = (payload) => {
    return instance.delete(`budget_flights/${payload.id}`, {params: {...{advertiser_id: payload.advertiser_id}, ...getAccountPlatformObj(true)}});
}

export const getCampaignSingleDataApi = (id) => {
    return instance.get(`campaigns/${id}`, {params: {...getAccountPlatformObj()}});
}

export const putCampaignApi = (payload) => {
    return instance.put(`campaigns/${payload.id}`, {...payload, ...getAccountPlatformObj(false, true)});
}

export const putCampaignStrictApi = (payload) => {
    return instance.put(`campaigns/strict/${payload.id}`, {...payload, ...getAccountPlatformObj(false, true)});
}

// Line Items
export const postLineitemApi = (payload) => {
    return instance.post(`line_items`, {...payload, ...getAccountPlatformObj(true)});
}

export const postStrictLineitemApi = (payload) => {
    return instance.post(`line_items/strict`, {...payload, ...getAccountPlatformObj(true)});
}

export const postLineItemFindAndReplaceApi = (payload) => {
    if(payload.select_all) {
        Object.assign(payload.line_item_parameters,  getAccountPlatformObj(true)); 
    }
    return instance.post(`line_items/findAndReplace`, {...payload, ...getAccountPlatformObj(true)});
}

export const postSaveQueryParamsApi = (payload) => {
    return instance.post(`query_params`, {...payload, ...getAccountPlatformObj(true)});
}

export const getQueryParamsApi = (payload) => {
    return instance.get(`query_params/${payload.id}`);
}


export const isLineItemNameExistApi = (payload) => {
    return instance.get(`line_items/findByName`, {params: {...payload}});
}

export const deleteLineItemApi = (payload) => {
    return instance.delete(`line_items/${payload.id}`);
}
export const deleteBulkLineItemApi = (payload) => {
    return instance.delete(`line_items/bulkDelete`, {data: {...payload, ...getAccountPlatformObj(true)}});
}
export const deleteBulkAssLineItemCreativeApi = (payload) => {
    return instance.delete(`creative_line_items/bulkDelete`, {data: {...payload, ...getAccountPlatformObj(true)}});
}
export const deleteAssLineItemApi = (payload) => {
    return instance.delete(`creative_line_items/${payload.id}`);
}

export const putAssLineItemApi = (payload) => {
    return instance.put(`creative_line_items/${payload.id}`, {...payload, ...getAccountPlatformObj(false, true)});
}

export const putLineitemApi = (payload) => {
    return instance.put(`line_items/${payload.id}`, {...payload, ...getAccountPlatformObj(false, true)});
}


export const putLineitemStrictApi = (payload) => {
    return instance.put(`line_items/strict/${payload.id}`, {...payload, ...getAccountPlatformObj(false, true)});
}


export const getCampaignLineItemApi = (payload) => {
    let refinedPayload = getRefineObj(payload);
    let configObject = {
        params: {
            ...refinedPayload.payload,
            ...getAccountPlatformObj()
        },
        ...refinedPayload.cancelToken
    };
    return instance.get(`line_items`, configObject);
}

export const postLineitemTargetingApi = (payload) => {
    return instance.post(`targeting_expressions`, {...payload, ...getAccountPlatformObj()});
}

export const putLineitemTargetingApi = (payload) => {
    let paramId = payload.id;
    delete payload.id;
    console.log('call getLineitemTargetingApi')
    return instance.put(`targeting_expressions/${paramId}`, {...payload, ...getAccountPlatformObj()});
}

export const getLineitemTargetingApi = (payload) => {
    console.log('call getLineitemTargetingApi')
    return instance.get(`targeting_expressions/${payload.id}`, {params: {...getAccountPlatformObj()}});
}

export const getAdvertiserCreativeListApi = (payload) => {
    return instance.get(`creatives`, {params: {...payload, ...getAccountPlatformObj()}});
}

export const postCreativeLineitemApi = (payload) => {
    return instance.post(`creative_line_items`, {...payload, ...getAccountPlatformObj(true)});
}
export const postBulkCreativeLineitemApi = (payload) => {
    return instance.post(`/creative_line_items/bulkInsert`, {...payload, ...getAccountPlatformObj(true)});
}
export const postCreativeLineItemFindAndReplaceApi = (payload) => {
    return instance.post(`creative_line_items/findAndReplace`, {...payload, ...getAccountPlatformObj(true)});
}

export const getLineitemCreativeListApi = (payload) => {
    return instance.get(`creative_line_items`, {params: {...payload, ...getAccountPlatformObj()}});
}

export const getCustomListApi = (payload) => {
    let paramPayload = {...payload, ...getAccountPlatformObj()}
    if (payload.isGlobal) {
        delete paramPayload.account_id;
        delete paramPayload.isGlobal
    }
    return instance.get(`custom_lists`, {params: paramPayload});
}

export const getAppNameLookupApi = (payload) => {
    return instance.get(`/app_lookups`, {params: {...payload, ...getAccountPlatformObj()}});
}
export const getAppLookupFieldApi = (payload) => {
    return instance.get(`/app_lookups_field/${payload.app_lookups_field}`, {params: {...payload, ...getAccountPlatformObj()}});
}
export const getProvidersApi = (payload) => {

    return instance.get(`/providers`, {params: {...payload}});
}


export const getRefApi = (url_id, payload) => {
    return instance.get(`ref/${url_id}`, {params: {...payload}});
}

export const getDefaultRole = () => {
    return instance.get(`roles/default`);
}

export const getRoleById= (id) => {
    return instance.get(`roles/${id}`);
}

export const getLineItemSingleDataApi = (id) => {
    return instance.get(`line_items/${id}`, {params: {...getAccountPlatformObj()}});
}

export const getLineitemOurSegmentApi = (payload) => {
    let paramPayload = {...payload, ...getAccountPlatformObj()}
    if (payload.isGlobal) {
        delete paramPayload.account_id;
        delete paramPayload.isGlobal;
    }

    if(payload.segment_keys){
        let postObject = {};
        postObject.segment_keys=payload.segment_keys
        delete payload.segment_keys;
        return instance.post(`segments/search`,  {...postObject},{params: payload});
    } else{
        return instance.get(`segments`,  {params: paramPayload});
    }



}

export const getLineitemThirdpartySegmentApi = (payload) => {
    let postObject = {};
    if (payload.keys) {
        postObject.keys = payload.keys;
        delete payload.keys;
    }
    if(payload.data_provider_ids){
        postObject.data_provider_ids=payload.data_provider_ids
        delete payload.data_provider_ids;
        return instance.post(`third_party/segment_tree_search`, {...postObject}, {params: {...payload}});
    }else{
        return instance.post(`third_party/segment_tree`, {...postObject}, {params: {...payload, ...getAccountPlatformObj()}});
    }

}

export const getLineitemThirdpartySegmentSearchApi = (payload) => {
    return instance.get(`third_party/segment_search`, {params: {...payload, ...getAccountPlatformObj()}});
}

export const getRealTimeStatApi = (payload = {}) => {
    return instance.get(`reports/line_item_performance_stats`, {params: {...payload, ...getAccountPlatformObj()}});
}

export const postBulkTargetingLineItemApi = (payload = {}) => {
    return instance.post(`line_items/bulkTargeting`, {...payload, ...getAccountPlatformObj(true)});
}

export const postBidModifierApi = (payload = {}) => {
    return instance.post(`bid_modifiers`, {...payload, ...getAccountPlatformObj(true)});
}

export const putBidModifierApi = (payload = {}) => {
    return instance.put(`bid_modifiers/${payload.id}`, {...payload, ...getAccountPlatformObj(false, true)});
}

export const getBidModifierApi = (payload = {}) => {
    return instance.get(`bid_modifiers`, {params: {...payload, ...getAccountPlatformObj()}});
}

export const getSingleBidModifierApi = (payload = {}) => {
    return instance.get(`bid_modifiers/${payload.id}`, {params: {...payload, ...getAccountPlatformObj()}});
}


export const postDeliveryModifierApi = (payload = {}) => {
    
    return instance.post(`delivery_modifiers`, {...payload, ...getAccountPlatformObj(true)});
}

export const postDeliveryBulkModifierApi = (payload = {}) => {
    return instance.post(`delivery_modifiers/bulkEdit`, {...payload, ...getAccountPlatformObj(true, true)});
}


export const postDeliveryBulkCappingApi = (payload = {}) => {
    return instance.post(`delivery_cappings/bulkEdit`, {...payload, ...getAccountPlatformObj(true, true)});
}
export const postBulkBidModifierApi = (payload = {}) => {
    return instance.post(`bid_modifiers/bulkEdit`, {...payload, ...getAccountPlatformObj(true, true)});
}

export const putDeliveryModifierApi = (payload = {}) => {
    return instance.put(`delivery_modifiers/${payload.id}`, {...payload, ...getAccountPlatformObj(false, true)});
}

export const getDeliveryModifierApi = (payload = {}) => {
    console.log('getDeliveryModifierApi',payload)
    if(payload.select_all) {
        Object.assign(payload.line_item_parameters,  getAccountPlatformObj(true)); 
        payload.line_item_parameters = JSON.stringify(payload.line_item_parameters)
    }
    return instance.get(`delivery_modifiers`, {params: {...payload, ...getAccountPlatformObj()}});
}

export const getSingleDeliveryModifierApi = (payload = {}) => {
    let id = payload.id;
    if (payload.id) {
        delete payload.id;
    }
    return instance.get(`delivery_modifiers/${id}`, {params: {...payload, ...getAccountPlatformObj()}});
}


export const postDeliveryCappingsApi = (payload = {}) => {
    return instance.post(`delivery_cappings`, {...payload, ...getAccountPlatformObj(true)});
}
export const putDeliveryCappingsApi = (payload = {}) => {
    return instance.put(`delivery_cappings/${payload.id}`, {...payload, ...getAccountPlatformObj(false, true)});
}

export const getDeliveryCappingsApi = (payload = {}) => {
    if(payload.select_all) {
        Object.assign(payload.line_item_parameters,  getAccountPlatformObj(true)); 
        payload.line_item_parameters = JSON.stringify(payload.line_item_parameters)
    }
    return instance.get(`delivery_cappings`, {params: {...payload, ...getAccountPlatformObj()}});
}

export const getSingleDeliveryCappingsApi = (payload = {}) => {
    let id = payload.id;
    if (payload.id) {
        delete payload.id;
    }
    return instance.get(`delivery_cappings/${id}`, {params: {...payload, ...getAccountPlatformObj()}});
}
export const postDeliveryBulkCappingsApi = (payload = {}) => {
    return instance.post(`delivery_cappings/bulkEdit`, {...payload, ...getAccountPlatformObj(true, true)});
}

//Creatives
export const getCreatives = (payload = {}) => {
    let refinedPayload = getRefineObj(payload);
    let configObject = {
        params: {
            ...refinedPayload.payload,
            ...getAccountPlatformObj()
        },
        ...refinedPayload.cancelToken
    };
    return instance.get(`creatives`, configObject);
}
export const postCreativeApi = (payload) => {
    return instance.post(`creatives`, {...payload, ...getAccountPlatformObj(true)});
}
export const getCreativesRecord = (payload) => {
    return instance.get(`creatives/download`, {
        params: {...getAccountPlatformObj(), ...payload},
        responseType: 'blob',
    });
}
export const postCreativeVideoApi = (payload) => {
    return instance.post(`video_assets`, {params: {...payload, ...getAccountPlatformObj(true)}});
}
export const duplicateCreativeApi = (payload) => {
    return instance.post(`creatives/duplicate`, {...payload, ...getAccountPlatformObj(true)});
}
export const putCreativeApi = (payload) => {
    return instance.put(`creatives/${payload.id}`, {...payload, ...getAccountPlatformObj(false, true)});
}
export const deleteCreativeApi = (payload) => {
    return instance.delete(`creatives/${payload.id}`);
}
export const deleteBulkCreativeApi = (payload) => {
    return instance.delete(`creatives/bulkDelete`, {data: {...payload, ...getAccountPlatformObj(true)}});
}
export const getCreativeSingleDataApi = (id) => {
    return instance.get(`creatives/${id}`, {params: {...getAccountPlatformObj()}});
}

export const getCreativeExistingAssetsApi = (payload = {}) => {
    return instance.get(`video_assets`, {params: {...payload, ...getAccountPlatformObj()}});
}
export const getCreativeExistingCompanionAssetsApi = (payload = {}) => {
    return instance.get(`companion_assets`, {params: {...payload, ...getAccountPlatformObj()}});
}
export const getCreativeExistingBannerAssetsApi = (payload = {}) => {
    return instance.get(`banner_assets`, {params: {...payload, ...getAccountPlatformObj()}});
}
export const getCreativeExistingAudioAssetsApi = (payload = {}) => {
    return instance.get(`audio_assets`, {params: {...payload, ...getAccountPlatformObj()}});
}
export const getCreativeAddOnApi = (payload = {}) => {
    return instance.get(`creative_addons`, {params: {...payload, ...getAccountPlatformObj()}});
}
export const postCreativeFindAndReplaceApi = (payload) => {
    return instance.post(`creatives/findAndReplace`, {...payload, ...getAccountPlatformObj(true)});
}

export const creativeExists = (payload) => {
    return instance.get(`creatives/findByName`, {params: {...payload, ...getAccountPlatformObj(true)}});
}
export const getCreativeApprovalQueues = (payload) => {
    return instance.get(`/creative_approval_queues`, {params: {...payload, ...getAccountPlatformObj()}});
}
export const getCreativeApprovalHistories = (payload) => {
    return instance.get(`/creative_approval_queue_histories`, {params: {...payload, ...getAccountPlatformObj()}});
}
export const postCreativeUrlAssetApi = (payload) => {
    return instance.post(`creative_assets`, {...payload, ...getAccountPlatformObj(true)});
}
export const fetchSingleCreativeUrlAssetApi = (payload) => {
    return instance.get(`creative_assets/${payload.batch_id}`, {params: {...payload, ...getAccountPlatformObj()}});
}
export const putCreativeUrlAssetApi = (payload) => {
    const batch_id = payload.batch_id;
    const advertiser_id = payload.advertiser_id;
    delete payload.batch_id;
    return instance.put(`creative_assets/${batch_id}`, {...payload, ...getAccountPlatformObj()}, {params: {advertiser_id: advertiser_id, ...getAccountPlatformObj()}});
}


export const getCreativeSegmentsApi = (payload = {}) => {
    return instance.get(`/creative_segments`, {params: {...payload, ...getAccountPlatformObj()}});
}

// retargeting Segments
export const getRetargetingSegmentsApi = (payload = {}) => {
    return instance.get(`/${payload.type}_segments`, {params: {...payload, ...getAccountPlatformObj()}});
}

export const getSingleRetargetingSegmentApi = (payload) => {
    return instance.get(`/${payload.type}_segments/${payload.id}`, {params: {...getAccountPlatformObj()}});
}

export const postRetargetingSegmentApi = (payload) => {
    if (payload.type === 'creative') {
        return instance.post(`/creative_segments`, {...payload, ...getAccountPlatformObj(true)});
    } else if (payload.type === 'location') {
        return instance.post(`/location_segments`, {...payload, ...getAccountPlatformObj(true)});
    } else if (payload.type === 'web') {
        return instance.post(`/web_segments`, {...payload, ...getAccountPlatformObj(true)});
    } else {
        return false;
    }
}

export const putRetargetingSegmentApi = (payload) => {
    if (payload.type === 'creative') {
        return instance.put(`/creative_segments/${payload.id}`, {...payload, ...getAccountPlatformObj(true)});
    } else if (payload.type === 'location') {
        return instance.put(`/location_segments/${payload.id}`, {...payload, ...getAccountPlatformObj(true)});
    } else if (payload.type === 'web') {
        return instance.put(`/web_segments/${payload.id}`, {...payload, ...getAccountPlatformObj(true)});
    } else {
        return false;
    }
}

export const checkRetargetingNameExistApi = (payload) => {
    return instance.get(`/${payload.type}_segments/findByTitle?title=${payload.title}`);
}

// dashboard end point api

export const deleteDashboardWidget = (id) => {
    return instance.delete(`dashboard_widgets/${id}`);
}

export const getDashboardStats = (payload = {}) => {
    let url = `dashboard/${payload.url ? payload.url : "undefined"}`;
    if(payload.request_url)  {
        url = payload.request_url;
    }

    let refinedPayload = getRefineObj(payload);
    let configObject = {
        params: {
            ...refinedPayload.payload,
            ...getAccountPlatformObj()
        },
        ...refinedPayload.cancelToken
    };
    return instance.get(url, configObject);
}
export const getProviderStats = (payload = {}) => {
    const request_url = payload.url;
    delete payload.url;
    return instance.get(`${request_url}`, {params: {...payload, ...getAccountPlatformObj()}});
}
export const downloadDashboards = (payload = {}) => {
    return instance.get(`downloads`, {params: {...payload, ...getAccountPlatformObj()}});
}
export const saveDashboard = (payload = {}) => {
    return instance.post(`dashboard_widgets`, {...payload, ...getAccountPlatformObj(true)});
}

export const fetchDashboard = (payload = {}) => {
    return instance.get(`dashboard_widgets`, {params: {...payload, ...getAccountPlatformObj(true)}});
}

export const fetchInsights = (payload = {}) => {

    let refinedPayload = getRefineObj(payload);
    let configObject = {
        params: {
            ...refinedPayload.payload,
            ...getAccountPlatformObj()
        },
        ...refinedPayload.cancelToken
    };

    return instance.get(`dashboard/insights`, configObject);
}

export const updateDashboardWidget = (id, payload = {}) => {
    return instance.put(`dashboard_widgets/${id}`, {...payload, ...getAccountPlatformObj()});
}


//Vendors
export const getVendors = (payload = {}) => {
    return instance.get(`vendors`, {params: {...payload, ...getAccountPlatformObj()}});
}
export const postVendorApi = (payload) => {
    return instance.post(`vendors`, {...payload, ...getAccountPlatformObj(true)});
}
export const putVendorApi = (payload) => {
    return instance.put(`vendors/${payload.id}`, {...payload, ...getAccountPlatformObj(false, true)});
}
export const deleteVendorApi = (payload) => {
    return instance.delete(`vendors/${payload.id}`);
}
export const getVendorSingleDataApi = (id) => {
    return instance.get(`vendors/${id}`, {params: {...getAccountPlatformObj()}});
}
export const vendorExist = (payload) => {
    return instance.get(`vendors/findByName`, {params: {...payload}});
}
export const getVendorsRecord = (payload) => {
    return instance.get(`vendors/download`, {
        params: {...getAccountPlatformObj(), ...payload},
        responseType: 'blob',
    });
}

export const getRillDataApi = (payload) => {
    return instance.post(`reports/rilldata`, {...payload, ...getAccountPlatformObj(true)});
}

//Global search api
export const getObjectSearch = (payload = {}) => {
    return instance.get(`objects/search`, {params: {...payload, ...getAccountPlatformObj()}});
}

//Statistics data apis
export const getWidgetDataApi = (payload = {}) => {
    return instance.get(`reports/campaign_performance_agg`, {params: {...payload, ...getAccountPlatformObj()}});
}

export const getStatFieldApi = (payload = {}) => {
    return instance.get(`stats_fields`, {params: {...payload, ...getAccountPlatformObj()}});
}
export const postStatFieldApi = (payload = {}) => {
    return instance.post(`stats_fields`, {...payload, ...getAccountPlatformObj(false, false, false, false, true)});
}

export const putStatFieldApi = (payload = {}) => {
    let id = payload.id;
    delete payload.id;
    return instance.put(`stats_fields/${id}`, {...payload, ...getAccountPlatformObj(false, false, false, false, true)});
}

// PresetApi
export const postPresetApi = (payload) => {
    return instance.post(`presets`, {...payload, ...getAccountPlatformObj(true)});
}

export const putPresetApi = (payload) => {
    let id = payload.id;
    delete payload.id;
    return instance.put(`presets/${id}`, {...payload, ...getAccountPlatformObj(true)});
}

export const getPresetsApi = (payload = {}) => {
    return instance.get(`presets`, {params: {...payload, ...getAccountPlatformObj()}});
}

export const getPresetsModifierApi = (payload = {}) => {
    return instance.get(`presets`, {params: {...payload, ...getAccountPlatformObj()}});
}
export const getPresetsCappingApi = (payload = {}) => {
    return instance.get(`presets`, {params: {...payload, ...getAccountPlatformObj()}});
}
export const getPresetsBidModifierApi = (payload = {}) => {
    return instance.get(`presets`, {params: {...payload, ...getAccountPlatformObj()}});
}

export const getSinglePresetApi = (id) => {
    return instance.get(`presets/${id}`, {params: {...getAccountPlatformObj()}});
}

export const getPresetsRecord = (payload) => {
    return instance.get(`presets/download`, {
        params: {...getAccountPlatformObj(), ...payload},
        responseType: 'blob',
    });
}

export const deletePresetApi = (payload) => {
    return instance.delete(`presets/${payload.id}`);
}

export const postBiReportApi = (payload = {}) => {
    return instance.post(`reports/metabase`, {...payload});
}


export const advanceReports = (payload = {}, uri = 'advance_reports', downloadType = 'json') => {
    let refinedPayload = getRefineObj(payload);
    let paylodData =  {
        ...refinedPayload.payload,
        ...getAccountPlatformObj(true)
    };
    let configObject = {
        responseType: downloadType == 'json' ? 'json' : 'blob'
    };
    configObject = {...configObject , ...refinedPayload.cancelToken};
    console.log("configObject", paylodData, configObject);
    return instance.post(uri, paylodData, configObject);
}

export const getAdvanceReports = (payload = {}) => {
    return instance.get(`advance_reports`, {params: {...payload, ...getAccountPlatformObj(true)}});
}

export const getSingleAdvanceReports = (id) => {
    return instance.get(`advance_reports/${id}`, {params: {...getAccountPlatformObj()}});
}

export const deleteAdvanceReport = (id) => {
    return instance.delete(`advance_reports/${id}`);
}



// fate api

export const forcast = (payload) => {
    return javaAPIInstance.get(`forecast`, {params: {...payload}});
}
export const getAssets = (payload) => {
    return instance.get(`assets`, {params: {...payload}});
}
export const assets = (payload) => {
    return instance.post(`assets`, {...payload, ...getAccountPlatformObj(true)});
}
export const getSingleForecast = (id) => {
    return javaAPIInstance.get(`forecast/${id}`, {params: {...getAccountPlatformObj()}});
}

export const getForecastImpressions = (id) => {
    return javaAPIInstance.get(`forecast/impression/${id}`);
}

export const fetchImpressions = (payload) => {
    return javaAPIInstance.post(`forecast/process_impression`, {...payload, ...getAccountPlatformObj(true)});
}

export const postForecastApi = (payload) => {
    return javaAPIInstance.post(`forecast`, {...payload, ...getAccountPlatformObj(true)});
}
export const putForecastApi = (payload) => {
    return javaAPIInstance.put(`forecast/${payload.id}`, {...payload, ...getAccountPlatformObj(false, true)});
}
export const forecastExistsApi = (payload) => {
    return javaAPIInstance.get(`forecast/validate/title`, {params: {...payload, ...getAccountPlatformObj(false, false)}});
}
export const downloadCensus = (payload) => {
    return javaAPIInstance.post(`forecast/export-census`, {...payload, ...getAccountPlatformObj(false)},{responseType: 'blob'});
}
export const deleteForecastApi = (payload) => {
    return javaAPIInstance.delete(`forecast/${payload.id}`);
}

export const downloadForecastSummary = (payload) => {
    return javaAPIInstance.get(`/forecast/export/${payload.id}`, {
        params: {...getAccountPlatformObj(), ...payload},
        responseType: 'blob',
    });
}
export const states = (payload) => {
    return javaAPIInstance.get(`/forecast/listState`, {params: {...payload}});
}
export const counties = (payload) => {
    return javaAPIInstance.get(`/forecast/listCounty`, {params: {...payload}});
}
export const DMA = (payload) => {
    return javaAPIInstance.get(`/forecast/dmas`, {params: {...payload}});
}
export const congress = (payload) => {
    return javaAPIInstance.get(`/forecast/congress`, {params: {...payload}});
}
export const fetchPolygons = (payload) => {
    return javaAPIInstance.post(`/forecast/polygon`, {...payload, ...getAccountPlatformObj(true)});
}
export const fetchCensus = (payload) => {
    return javaAPIInstance.post(`/forecast/census`, {...payload, ...getAccountPlatformObj(true)});
}
export const groups = (payload) => {
    return instance.get(`group`, {params: {...payload}});
}
export const postGroupApi = (payload) => {
    console.log("postGroupApi payload", payload);
    return instance.post(`group`, {...payload, ...getAccountPlatformObj(true)});
}
export const putGroupApi  = (payload) => {
    return instance.put(`group/${payload.id}`, {...payload, ...getAccountPlatformObj(false, true)});
}
export const getSingleGroup = (id) => {
    return instance.get(`group/${id}`, {params: {...getAccountPlatformObj()}});
}
export const groupExistsApi = (payload) => {
    return instance.get(`group/validate/title`, {params: {...payload, ...getAccountPlatformObj(false, false)}});
}
export const proposals = (payload) => {
    return instance.get(`proposal`, {params: {...payload}});
}
export const getSingleProposal = (id) => {
    return instance.get(`proposal/${id}`, {params: {...getAccountPlatformObj()}});
}
export const postProposalApi = (payload) => {
    return instance.post(`proposal`, {...payload, ...getAccountPlatformObj(true)});
}
export const putProposalApi  = (payload) => {
    return instance.put(`proposal/${payload.id}`, {...payload, ...getAccountPlatformObj(false, true)});
}
export const proposalExistsApi = (payload) => {
    return instance.get(`proposal/validate/title`, {params: {...payload, ...getAccountPlatformObj(false, false)}});
}
export const getAttributions = (payload) => {
    let url = payload.url;
    if (payload.url) {
        delete payload.url;
    }
    return instance.get(`${url}`, {params: {...payload, ...getAccountPlatformObj()}});
}

export const exportAttributionsRecord = (payload) => {
    let url = payload.url;
    delete payload.url;
    return instance.get(url, {
        params: {...getAccountPlatformObj(), ...payload},
        responseType: 'blob',
    });
}

export const getApi = (url, payload) => {
    return instance.get(`${url}`, {params: {...payload, ...getAccountPlatformObj()}});
}

export const postApi = (url, payload, isMultipart = false) => {
    let headers = {};
    let finalObj = {...payload, ...getAccountPlatformObj(true)};
    if(isMultipart) {
        headers = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }
        finalObj = payload;
        // Append account info fields
        const accountInfo = getAccountPlatformObj(true);
        for (const key in accountInfo) {
            finalObj.append(key, accountInfo[key]);
        }
    }
    return instance.post(`${url}`, finalObj, headers);
}

export const putApi = (url, payload, isMultipart = false) => {
    let headers = {};
    let finalObj = {...payload, ...getAccountPlatformObj(false, true)};
    if(isMultipart) {
        headers = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }
        finalObj = payload;
    }
    return instance.put(`${url}`, finalObj, headers);
}

export const getProviderData = (payload) => {
    return instance.get(`/providers`, {params: {...payload}});
}

// ios 

export const sendDocumentForSign = (payload) => {
    return instance.post(`io/docusign/send`, payload);
}

export const envelopeDownload = (payload) => {
    return instance.get(`io/docusign/envelope/download`, {params: payload, responseType: 'blob'});
}


export const getIO = (payload) => {
    return instance.get(`ios`, {params: payload});
}

export const getCampaign = (payload) => {
    return instance.get(`io/campaigns/${payload.id}`);
}

export const getZipCodeListApi = (payload) => {
    return instance.get(`io/zipcode_list`, {params: payload});
}

export const getVersionIO = (payload) => {
    return instance.get(`io/version/${payload.id}`);
}

export const getSingleIO = (payload) => {
    return instance.get(`io/edit/${payload.id}?response_type=json`);
}

export const getIOComments = (payload) => {
    return instance.get(`io/comment`, {params: payload});
}

export const postIOComments = (payload) => {
    return instance.post(`io/comment`, payload);
}

export const postIO = (payload) => {
    return instance.post(`io/request/post`, payload);
}

export const removeIO = (payload) => {
    return instance.delete(`io/${payload.id}`);
}

export const fileupload = (payload) => {
    return instance.post(`io/file/upload`, payload);
}

export const putIO = (payload) => {
    let editID = payload.id;
    delete payload.id;
    return instance.post(`io/request/put?io_id=${editID}`, payload);
}

export const updateIoCustomField = (payload) => {
    return instance.put(`io/custom_field`, payload);
}

export const getIOMeta = (payload) => {
    let type = payload.type;
    return instance.get(`io/meta/${type}`, {params: {...payload}});
}

export const getCampignTypes = () => {
    return instance.get(`io/campaign_types`);
}


export const getCampaignPurchaser = () => {
    return instance.get(`io/campaign_purchasers`);
}

export const getIoAssets = (payload) => {
    return instance.get(`io/assets`, {params: {...payload}});
}

export const getListOfCampaigns = (payload) => {
    return instance.get(`io/campaign/list`, {params: {...payload}});
}

export const getIOEnvelopeStatus = (payload) => {
    return instance.get(`io/docusign/envelope/status`, {params: {...payload}});
}

export const generateIOApi = (payload) => {
    return instance.post(`io/campaign/generate`, {...payload});
}

export const isDspIdExistApi = (payload) => {
    return instance.get(`io/dsp/${payload.id}`, {...payload});
}


export const getPixels = (payload) => {
    return instance.get(`web_pixels`, {params: {...getAccountPlatformObj(), ...payload}});
}

export const checkCname = (cname) => {
    return instance.get(`web_pixels/validateCNAME?domain=${cname}`);
}

export const getPixelPages = (payload) => {
    return instance.get(`web_pixels/page`, {params: payload});
}

export const getRefineObj = (payload) => {
    let cancelToken = {};
    if(payload.cancelToken) {
        cancelToken = { cancelToken: payload.cancelToken };
        delete payload.cancelToken;
    }
    return {payload, cancelToken};
}
