/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import axios from "axios";
import Swal from "sweetalert2";
import { authHeader } from './authservice/auth-header';
import { getPlatformRedirectUrl } from './micro-service';

const javaInstance = axios.create({
    baseURL: `${process.env.VUE_APP_JAVA_API_ENDPOINT}rest`
});

// request interceptor
javaInstance.interceptors.request.use(
    async config => {
        let authData = await authHeader();

        // Do something before request is sent
        if (authData['Authorization']) {
            config.headers["x-api-token"] = authData['Authorization'];
        }

        if (authData['token']) {
            config.headers["Authorization"] = `Bearer ${authData['token']}`;
        }
        config.headers["x-app-id"] = 'octilliontv';

        if (['put', 'post'].includes(config.method) && config.data) {
            let payloadData = config.data;
            if (payloadData != null && payloadData != undefined && (payloadData.deleted_at == '' || payloadData.deleted_at != '')) {
                delete payloadData.deleted_at;
            }
            if (payloadData.advertiser_id) {
                payloadData.advertiser_id = parseInt(payloadData.advertiser_id);
            }
            config.data = payloadData;
        }
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

// Add a response interceptor
javaInstance.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    console.log(error.response);
    if (error.response && 401 === error.response.status && !error.response.config.url.includes('/authenticate')) {
        let second = 5;
        Swal.fire({
            title: "Session Expired",
            text: `Your session has expired. You will be redirected to login page automatically in ${second}sec.`,
            type: "warning",
            showCancelButton: false,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Login",
            closeOnConfirm: false
        }).then(result => {
            if (result.value) {
                window.localStorage.setItem('redirectFrom', window.location.pathname + window.location.search);
                window.location = '/logout';
            }
        });
        setTimeout(() => {
            window.localStorage.setItem('redirectFrom', window.location.pathname + window.location.search);
            window.location = '/logout';
        }, 5000);
    } else if (error.response && 403 === error.response.status) {
        console.log("error.response", error.response);
        let request = error.response.config.url.split('/');
        Swal.fire({
            title: "Access Denied",
            text: `You don't have permission to access "${request[request.length - 1]}".`,
            type: "warning",
            showCancelButton: false,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Ok",
            closeOnConfirm: false
        }).then(result => {
            if (result.value) {
                window.location = getPlatformRedirectUrl();
            }
        });
    }
   
    else if (error.response && 412 === error.response.status) {

        Swal.fire({
            title: "Invalid Precondition Failed",
            text: `${error.response.data.message}`,
            type: "error",
            showCancelButton: false,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Dashboard",
            closeOnConfirm: false
        }).then(result => {
            if (result.value) {
                window.location = getPlatformRedirectUrl();
            }
        });
    } else {
        if (axios.isCancel(error)) {
            console.log('Request canceled:', error.message);
            return Promise.reject({message: error.message, status: 'cancelled'});
        } else {
            // Ensure error.response and error.response.data are defined
            if (typeof error.response.data === 'object') {
                error.response.data.status = error.response.status;
            }
            // Return the error response
            return Promise.reject(error.response);
        }
    }
});

export const javaAPIInstance = javaInstance;