/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import {getFirebaseBackend} from '../../authUtils.js'
import {userService} from '../../helpers/authservice/user.service';
import {
    userAuth,
    switchUserAccount,
    checkEmailExistForgetPassApi,
    requestResetPasswordApi,
    updatePasswordApi
} from "../../helpers/api";
import router from '../../router/index'
import { getPlatformRedirectUrl } from '../../helpers/micro-service';

const user = JSON.parse(localStorage.getItem('user'));
export const state = user
    ? {
        status: {loggeduser: true},
        user,
        loginStatus: null,
        checkEmailExistForgetPassword: null,
        forgetPassRequestSuccess: null,
        forgetPassRequestErr: null,
        updateForgetPasswordSuccess: null,
        updateForgetPasswordErr: null,

    }
    : {
        status: {},
        user: null,
        loginStatus: null,
        checkEmailExistForgetPassword: null,
        forgetPassRequestSuccess: null,
        forgetPassRequestErr: null,
        updateForgetPasswordSuccess: null,
        updateForgetPasswordErr: null,

    };

export const mutations = {
    // SET_CURRENT_USER(state, newValue) {
    //     state.currentUser = newValue
    //     saveState('auth.currentUser', newValue)
    // },
    loginRequest(state, user) {
        state.status = {loggingIn: true};
        state.user = user;

    },
    loginSuccess(state, user) {
        state.status = {loggeduser: true};
        state.user = user;
        saveState('user', user)
    },
    loginFailure(state, newValue) {
        state.loginStatus = newValue;
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    setCheckEmailExistForgetPass(state, newValue) {
        state.checkEmailExistForgetPassword = newValue
    },
    setForgetPassSuccess(state, newValue) {
        state.forgetPassRequestSuccess = newValue
    },
    setForgetPassErr(state, newValue) {
        state.forgetPassRequestErr = newValue
    },
    setUpdateForgetPassSuccess(state, newValue) {
        state.updateForgetPasswordSuccess = newValue
    },
    setUpdateForgetPassErr(state, newValue) {
        state.updateForgetPasswordErr = newValue
    }
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.user
    },
    checkEmailExistForgetPassword: state => state.checkEmailExistForgetPassword,
    forgetPassRequestSuccess: state => state.forgetPassRequestSuccess,
    forgetPassRequestErr: state => state.forgetPassRequestErr,
    updateForgetPasswordSuccess: state => state.updateForgetPasswordSuccess,
    updateForgetPasswordErr: state => state.updateForgetPasswordErr,
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({state, dispatch}) {
        dispatch('validate')
    },

    // Logs in the current user.
    logIn({commit, dispatch, getters}, {email, password} = {}) {
        //console.log("{ email, password }", email, password);
        if (getters.loggedIn) return dispatch('validate')

        return userAuth({email, password}).then((response) => {
            const user = response
            //console.log("user", user);
            commit('SET_CURRENT_USER', user)
            return user
        });
    },
    signIn({dispatch, commit}, {email, password}) {
        //console.log(email, password);
        commit('loginRequest', {email});
        userAuth({email, password})
            .then(
                user => {
                    // console.log("signIn", user.data)
                    commit('loginSuccess', user.data);
                    commit('loginFailure', null);

                    console.log(user.data);

                    localStorage.setItem('accountTimeZone', user.data.user.timezone);
                    if (user.data.user && window.localStorage.getItem('lastSwitchAccount') && user.data.user.account_id !== window.localStorage.getItem('lastSwitchAccount') && user.data.user.multi_account) {
                        switchAccount(commit, user.data.user.email, window.localStorage.getItem('lastSwitchAccount'), user.data.user.platform_id);
                    } else if (window.localStorage.getItem('redirectFrom')) {
                        router.push(window.localStorage.getItem('redirectFrom'));
                    } else {
                        router.push(getPlatformRedirectUrl());
                    }
                    if(user.data && user.data.user && user.data.user.ui_preferences && user.data.user.ui_preferences.is_debug) {
                        localStorage.setItem('is_debug', true);
                    } else {
                        localStorage.removeItem('is_debug');
                    }
                },
                error => {
                    console.log("loginFailure", error);
                    commit('loginFailure', error);
                    dispatch('notification/error', error, {root: true});
                }
            );
    },
    // eslint-disable-next-line no-unused-vars
    switchAcct({dispatch, commit}, {email, account_id, platform_id, isManual = false}) {
        commit('loginRequest', {email});
        switchAccount(commit, email, account_id, platform_id, isManual);
    },
    // eslint-disable-next-line no-unused-vars
    checkEmailForgetPassword({dispatch, commit}, {email, account_id, platform_id}) {
        commit('loginRequest', {email});
        switchAccount(commit, email, account_id, platform_id);
    },
    // Logout the user
    logout({commit}) {
        userService.logout();
        commit('logout');
    },
    // register the user
    register({commit, dispatch, getters}, {email, password} = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getFirebaseBackend().registerUser(email, password).then((response) => {
            const user = response
            commit('SET_CURRENT_USER', user)
            return user
        });
    },

    // register the user
    // eslint-disable-next-line no-unused-vars
    /* resetPassword({commit, dispatch, getters}, {email} = {}) {
         if (getters.loggedIn) return dispatch('validate')

         return getFirebaseBackend().forgetPassword(email).then((response) => {
             const message = response.data
             return message
         });
},*/
    resetPassword({commit}, payload) {
        updatePasswordApi(payload).then(res => {
            commit('setUpdateForgetPassSuccess', res.data)
        }, (error) => {
            commit('setUpdateForgetPassErr', error.data)
        })
    },
    requestResetPassword({commit}, payload) {
        requestResetPasswordApi(payload).then(res => {
            commit('setForgetPassSuccess', res.data)
        }, (error) => {
            commit('setForgetPassErr', error.data)
        })
    },
    checkEmailExistForgetPassFunc({commit}, payload) {
        checkEmailExistForgetPassApi(payload).then(res => {
            commit('setCheckEmailExistForgetPass', res.data)
        }, (error) => {
            commit('setCheckEmailExistForgetPass', error.data)
        })
    },
    clearForgetPassword({commit}) {
        commit('setCheckEmailExistForgetPass', null);
        commit('setForgetPassSuccess', null);
        commit('setForgetPassErr', null);
        commit('updateForgetPasswordSuccess', null);
        commit('updateForgetPasswordErr', null);
    },
    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    validate({commit, state}) {
        if (!state.currentUser) return Promise.resolve(null)
        const user = getFirebaseBackend().getAuthenticatedUser();
        commit('SET_CURRENT_USER', user)
        return user;
    },
}

function switchAccount(commit, email, account_id, platform_id, isManual = false) {
    switchUserAccount({email, account_id, platform_id})
        .then(
            user => {
                //console.log("signIn", user.data)
                // console.log("TimeZone", user.data.user.timezone)
                if(user && user.data) {
                    localStorage.setItem('accountTimeZone', user.data.user.timezone);

                    if(user.data.switched_account){
                        sessionStorage.setItem(`oct_switched_account_id_${user.data.user.account_id}`, user.data.switched_account)
                    }
                    
                    let storedUser = localStorage.getItem('user')
                    if(storedUser){
                        storedUser = JSON.parse(storedUser)
                        storedUser['user']['account_id'] = user.data.switched_account
                        commit('loginSuccess', storedUser);
                        commit('loginFailure', null);
                    } else {
                        commit('loginFailure', new Error('Fail to switch account'));
                    }
                    // console.log("signIn", user.data)
                    
                    

                }

                window.location.reload()
                // if (window.localStorage.getItem('redirectFrom') && !isManual) {
                //     router.push(window.localStorage.getItem('redirectFrom'));
                // } else {
                //     router.push('/');
                // }

            },
            error => {
                console.log("loginFailure", error);
                commit('loginFailure', error);
                // dispatch('notification/error', error, {root: true});
            }
        );
}


// ===
// Private helpers
// ===

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
}
