/**
 * @file micro-service.js
 * @description 
 * @author 
 * @copyright 
 */
import * as XLSX from 'xlsx';
import Swal from "sweetalert2";
import { DspAuth } from '@octillion/auth'
import { getLoggedInUser } from './authservice/auth-header';

export function convertCSVtoJSON(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    
    reader.onload = function(e) {
      const text = e.target.result;
      const lines = text.split('\n');
      const headers = lines[0].split(',');
      const jsonData = [];

      for (let i = 1; i < lines.length; i++) {
        const obj = {};
        const currentLine = lines[i].split(',');

        headers.forEach((header, index) => {
          obj[header.trim()] = currentLine[index] ? currentLine[index].trim() : null;
        });

        jsonData.push(obj);
      }

      resolve(jsonData);
    };
    
    reader.onerror = function(error) {
      reject(error);
    };
    
    reader.readAsText(file);
  });
}

export function convertExceltoJSON(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function(e) {
        const data = new Uint8Array(e.target.result);
        const workbook = readXLSX(data);
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = sheetToJSON(worksheet);

        resolve(jsonData);
      };

      reader.onerror = function(error) {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  }

  function readXLSX(data) {
    const workbook = XLSX.read(data, { type: 'array' });
    return workbook;
  }

  function sheetToJSON(sheet) {
    const result = [];
    const range = XLSX.utils.decode_range(sheet['!ref']);
    const headers = [];

    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cell = sheet[XLSX.utils.encode_cell({ r: range.s.r, c: C })];
      if (cell && cell.t) {
        headers.push(XLSX.utils.format_cell(cell));
      }
    }

    for (let R = range.s.r + 1; R <= range.e.r; ++R) {
      const row = {};
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell = sheet[XLSX.utils.encode_cell({ r: R, c: C })];
        row[headers[C - range.s.c]] = cell ? XLSX.utils.format_cell(cell) : null;
      }
      result.push(row);
    }

    return result;
  }

  // Function to generate coordinates of a square polygon
export function generateSquareCoordinates(centerLat, centerLng, sideLength = 0.00002) {
  // Convert degrees to radians
  function degreesToRadians(degrees) {
      return degrees * (Math.PI / 180);
  }

  // Convert radians to degrees
  function radiansToDegrees(radians) {
      return radians * (180 / Math.PI);
  }

  // Earth's radius in meters (assuming a spherical Earth)
  const EARTH_RADIUS = 6371000; // meters

  // Half side length in meters
  const halfSideMeters = sideLength * EARTH_RADIUS;

  // Convert center coordinates to radians
  const centerLatRad = degreesToRadians(centerLat);
  const centerLngRad = degreesToRadians(centerLng);

  // Calculate offsets in radians
  const offsetLatRad = Math.asin(halfSideMeters / EARTH_RADIUS);
  const offsetLngRad = Math.asin(halfSideMeters / (EARTH_RADIUS * Math.cos(centerLatRad)));

  // Calculate square vertices in radians
  const vertices = [
      { lat: centerLatRad + offsetLatRad, lng: centerLngRad - offsetLngRad },
      { lat: centerLatRad + offsetLatRad, lng: centerLngRad + offsetLngRad },
      { lat: centerLatRad - offsetLatRad, lng: centerLngRad + offsetLngRad },
      { lat: centerLatRad - offsetLatRad, lng: centerLngRad - offsetLngRad }
  ];

  // Convert vertices back to degrees and return
  return vertices.map(vertex => ({
      lat: radiansToDegrees(vertex.lat),
      lng: radiansToDegrees(vertex.lng)
  }));
}

export function footerTableProps() {
  return { 'itemsPerPageOptions': [10, 25, 50, 100] };
}

export function showSuccessAlert(text, confirmButton, redirectUrl) {
  Swal.fire({
    title: "Good job!",
    allowOutsideClick: false,
    text: `${text}`,
    icon: "success",
    showCancelButton: false,
    confirmButtonColor: "#34c38f",
    cancelButtonColor: "#f46a6a",
    confirmButtonText: confirmButton
  }).then(result => {
    if (result.value) {
      window.location.href = redirectUrl
    }
  });
}

function capitalizeWords(str) {
  return str
      .replace(/_/g, ' ') // Replace underscores with spaces
      .toLowerCase() // Convert to lowercase
      .split(' ') // Split into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
      .join(' '); // Join words back into a string
}
export function generateReviewStyleOverview(json) {
  const fields = json.fields.map(field => `${capitalizeWords(field)}`).join(', ');
  const orders = json.orders ? json.orders.map(order => {
      return `${order.key} in ${order.order} order`;
  }).join(', ') : null;
  return `Review the details of your report where we <strong>select</strong> ${fields}, ${orders ? '<strong>sort by</strong>' : ''}.`;
}

export async function interceptorsRequestMiddleware(config) {
  config.headers["x-app-id"] = "octilliontv"
  const auth = DspAuth.getInstance()
  config.headers["Authorization"] = `Bearer ${await auth.getToken()}`
  return config;
}

export function converIntoFilterFormat(input) {
  const output = {};
  Object.keys(input).forEach(key => {
    if(input[key] != null && input[key] != '') {
      let filterType = key.includes('_id') ? 'number' : 'string';
      let type = key.includes('_id') ? 'equals' : 'contains';
      output[key] = {
        filterType,
        type,
        filter: input[key]
      };
    }
  });
  return output;
}


export function getUserAccountCountries() {
  if(localStorage.getItem('countries')) {
    return localStorage.getItem('countries').split(',');
  } else {
    return ['US', 'CA'];
  }
}


// Function to parse the POLYGON string and convert it to an array of coordinate objects
export function parsePolygon(polygonStr) {
  // Remove "POLYGON((" at the beginning and "))" at the end
  const coordinatesStr = polygonStr.replace("POLYGON((", "").replace("))", "");

  // Split the string by commas to get each coordinate pair
  const coordinates = coordinatesStr.split(",").map(coord => {
      const [lng, lat] = coord.trim().split(" ").map(Number);
      return { lat, lng };
  });

  return coordinates;
}

export function removeDuplicates(array, key) {
  const seen = new Set();
  return array.filter(item => {
    const uniqueKey = item[key];
    if (seen.has(uniqueKey)) {
      return false; // Skip if already seen
    }
    seen.add(uniqueKey);
    return true; // Keep if not seen
  });
}

export function downloadFormatType(type) {
  switch(type.toLowerCase()) {  // Ensure case-insensitivity by converting to lowercase
    case 'csv':
      return 'text/csv';
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case 'pdf':
      return 'application/pdf';
    default:
      // You might want to throw an error or return a default value
      return 'application/octet-stream';  // Generic binary stream type if an unknown type is provided
  }
}

export function getPlatformRedirectUrl() {
  return getLoggedInUser()?.user?.ui_preferences?.type == 'fate' ? "/" : "/advertisers";
}