/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import {getDashboardStats, getProviderStats, downloadDashboards} from "@/helpers/api";

export const state = {
    advertisersStats: [],
    creativesStats: [],
    dashboardStats: [],
    downloadDashboardStats: [],
    daypartStats: [],
    providersStats: [],
    videoStats: [],
    geoStats: [],
    geoHitMapStats: [],
    audienceStats:[],
    campaignStats: [],
    lineItemsStats: [],
    campaignPerformaceStats: []
};

export const getters = {
    advertisersStats: state => state.advertisersStats,
    creativesStats: state => state.creativesStats,
    dashboardStats: state => state.dashboardStats,
    downloadDashboardStats: state => state.downloadDashboardStats,
    daypartStats: state => state.daypartStats,
    providersStats: state => state.providersStats,
    videoStats: state => state.videoStats,
    devicesStats: state => state.devicesStats,
    geoStats: state => state.geoStats,
    geoHitMapStats: state => state.geoHitMapStats,
    campaignStats: state => state.campaignStats,
    lineItemsStats: state => state.lineItemsStats,
    audienceStats: (state) => state.audienceStats,
    campaignPerformaceStats: (state) => state.campaignPerformaceStats
};

export const mutations = {
    setAdvertisersStats(state, newValue) {
        state.advertisersStats = newValue
    },
    setCreativesStats(state, newValue) {
        state.creativesStats = newValue
    },
    setDashboardStats(state, newValue) {
        state.dashboardStats = newValue
    },
    setDownloadDashboardsSuccess(state, newValue) {
        state.downloadDashboardStats = newValue
    },
    setDaypartStats(state, newValue) {
        state.daypartStats = newValue
    },
    setProvidersStats(state, newValue) {
        state.providersStats = newValue
    },
    setVideoStats(state, newValue) {
        state.videoStats = newValue
    },
    setDevicesStats(state, newValue) {
        state.devicesStats = newValue
    },
    setGeoStats(state, newValue) {
        state.geoStats = newValue
    },
    setGeoHitMapStats(state, newValue) {
        state.geoHitMapStats = newValue
    },
    setCampaignStats(state, newValue) {
        state.campaignStats = newValue
    },
    setLineItemStats(state, newValue) {
        state.lineItemsStats = newValue
    },
    setAudienceStats(state, newValue) {
        state.audienceStats = newValue
    },
    setCampaignPerformaceStats(state, newValue) {
        state.campaignPerformaceStats = newValue
    }
};

export const actions = {
    fetchDashboardStats({commit}, payload) {
        if(payload.widget){
            getProviderStats(payload).then(res => {
                commit(payload.state, res.data)
            })
        } else {
            getDashboardStats(payload).then(res => {
                let result = res.data;
                    result.export = false;
                if(res && res?.config && res?.config?.params && res?.config?.params?.export) {
                    result.export = res?.config?.params?.export;
                }
                commit(payload.state, result)
            }, (error) => {
                console.log("Request Status", error);
            });
        }

    },
    downloadDashboardsStats({commit}, payload) {
        downloadDashboards(payload).then(res => {
            commit('setDownloadDashboardsSuccess', res.data)
        }, (error) => {
            commit('setDownloadDashboardsErr', error.data)
        })

    },


    
};
